<!-- 首页 -->
<template>
	<div class="Home-page">
		<div class="course-nav-wrap">
			<div class="course-container flex-jb-ac">
				<div class="nav-wrap flex-ac">
					公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~公司介绍文案~~
					<!-- <img src="../assets/image/icon-course-nav.png" alt="" />
					<span>课程</span> -->
				</div>
				<div class="search-wrap flex-ac">
					<input type="text" placeholder="搜索你想要的课程" @keyup.enter="handleSearch" v-model="searchWord" />
					<div class="search-btn flex-jc-ac" @click="handleSearch">
						<img class="icon" src="../assets/image/icon-search.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="content-container">
			<!-- <div class="filter-container flex">
				<div class="filter" @click="toggerFilter('')" :class="{ active: filterKeyLid == '' }">默认排序</div>
				<div class="filter" v-for="item in navList" :key="item.id" @click="toggerFilter(item.id)" :class="{ active: filterKeyLid == item.id }">{{ item.bq_name }}</div>
			</div> -->
			<div class="course-list">
				<div class="empty-box flex-jc-ac" v-if="courseList.length === 0">暂无课程</div>
				<openCourseListCell v-for="item in courseList" :key="item.kcid" :courseInfo="item" />
			</div>
			<div class="pagination flex-jc-ac" v-if="totalCourseCount > 20">
				<div class="btn first-pagination" :class="{ disabled: currentPage == 1 }" @click="changeCurrentPage(1)">首页</div>
				<el-pagination
					next-text="下一页"
					@current-change="getCourseList"
					:current-page.sync="currentPage"
					hide-on-single-page
					:page-size="pageSize"
					prev-text="上一页"
					layout="prev, pager, next"
					:total="totalCourseCount"
				>
				</el-pagination>
				<div class="btn end-pagination" :class="{ disabled: currentPage == lastPageCount }" @click="changeCurrentPage(lastPageCount)">尾页</div>
			</div>
		</div>
	</div>
</template>

<script>
// import { $getCourseList, $getNavList } from "../services/course.js";
import { $getCourseList } from "../services/openClass.js";
import openCourseListCell from "../components/course/openCourseListCell.vue";
export default {
	name: "Home",
	data() {
		return {
			searchWord: "", // 搜索关键字
			navList: [],
			filterKeyLid: "",
			pageSize: 20,
			totalCourseCount: 20,
			currentPage: 1,
			courseList: [],
		};
	},
	components: {
		openCourseListCell,
	},

	computed: {
		lastPageCount() {
			return Math.ceil(this.totalCourseCount / this.pageSize);
		},
	},

	created() {
		// this.getNavList();
		this.getCourseList();
	},

	methods: {
		async getCourseList() {
			const { data } = await $getCourseList({
				page: this.currentPage,
				limit: 20,
				lid: this.filterKeyLid,
				kcname: this.searchWord,
			});
			this.totalCourseCount = data.kccount;
			this.courseList = data.kclist;
		},
		// async getNavList() {
		// 	const { data } = await $getNavList();
		// 	this.navList = data;
		// },
		toggerFilter(key) {
			this.filterKeyLid = key;
			this.currentPage = 1;
			this.getCourseList();
		},
		handleSearch() {
			this.filterKeyLid = "";
			this.currentPage = 1;
			this.getCourseList();
		},
		changeCurrentPage(currentPage) {
			if (this.currentPage == currentPage) return;
			this.currentPage = currentPage;
			this.getCourseList();
		},
	},
};
</script>

<style scoped lang="less">
.course-nav-wrap {
	height: 160px;
	background: #f3f5f6;
	.course-container {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		.nav-wrap {
			font-size: 18px;
			color: #1c1f21;
			padding-right: 30px;
			// font-weight: bold;
			img {
				width: 52px;
				height: 52px;
				margin-right: 20px;
			}
		}
		.search-wrap {
			input {
				width: 280px;
				height: 36px;
				border: 1px solid #d7dadd;
				border-radius: 4px 0 0 4px;
				background-color: transparent;
				padding: 0 17px;
				font-size: 14px;
			}
			.search-btn {
				width: 36px;
				height: 36px;
				background: #545c63;
				opacity: 0.96;
				border-radius: 0px 4px 4px 0px;
				cursor: pointer;
				.icon {
					width: 19px;
					height: 18px;
				}
			}
		}
	}
}
.content-container {
	width: 1200px;
	margin: 0 auto;
	padding-top: 27px;
	.filter-container {
		height: 97px;
		padding-top: 27px;
		.filter {
			padding: 0 17px;
			height: 26px;
			line-height: 26px;
			background: #f3f5f6;
			border-radius: 13px;
			font-size: 13px;
			color: #545c63;
			cursor: pointer;
			user-select: none;
			margin-right: 28px;
			&.active {
				color: #fff;
				background: #545c63;
			}
		}
	}
	.course-list {
		display: flex;
		flex-wrap: wrap;
		min-height: 500px;
		.empty-box {
			width: 100%;
			height: 500px;
			font-size: 24px;
			color: #999;
		}
		.cell-courseList {
			margin-right: 24px;
			&:nth-of-type(4n) {
				margin-right: 0px;
			}
		}
	}
	.pagination {
		margin: 40px 0;
		font-size: 15px;
		color: #4d555d;
		.btn {
			cursor: pointer;
			&.disabled {
				color: #c0c4cc;
				cursor: not-allowed;
			}
		}
		.el-pagination {
			margin: 0 10px;
			display: flex;
			align-items: center;
			/deep/ span,
			/deep/ li {
				font-size: 15px;
				font-weight: normal;
			}
			/deep/ .el-pager li {
				line-height: 36px;
				&.active {
					color: #fff;
					width: 36px;
					height: 36px;
					background: #4d555d;
					border-radius: 50%;
				}
			}
		}
	}
}
</style>
